import React from 'react';
import styled from "styled-components";
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import {Results} from "./pages/Results";
import {Standings} from "./pages/Standings";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

const App = () => (
    <Router>
    <div className="App">
      <header className="App-header">
        <Container>
        <Routes>
          <Route path="/results" element={<Results />} />
          <Route path="/" element={<Standings />} />
        </Routes>
        </Container>
      </header>
    </div>
    </Router>
  );

export default App;
