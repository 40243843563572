import {TEAM_ID} from "../constants/TeamId";

// 2021 Money
//export const Money = {
//    [TEAM_ID.BOBNICKS]: 150,
//    [TEAM_ID.SCHRODINGER]: 275,
//    [TEAM_ID.INITTOWINIT]: 150,
//    [TEAM_ID.BOYLE]: 100,
//    [TEAM_ID.MENDOZA]: 0,
//    [TEAM_ID.MUDCATS]: 25,
//    [TEAM_ID.HOOFHEARTED]: 0,
//    [TEAM_ID.LUMBERJACKS]: 25,
//    [TEAM_ID.TIMMAHH]: 0,
//    [TEAM_ID.MOON]: 0,
//}

// 2022 Money
//export const Money = {
//    [TEAM_ID.BOBNICKS]: 0,
//    [TEAM_ID.SCHRODINGER]: 265,
//    [TEAM_ID.INITTOWINIT]: 60,
//    [TEAM_ID.BOYLE]: 0,
//    [TEAM_ID.MENDOZA]: 40,
//    [TEAM_ID.MUDCATS]: 20,
//    [TEAM_ID.HOOFHEARTED]: 0,
//    [TEAM_ID.LUMBERJACKS]: 20,
//    [TEAM_ID.TIMMAHH]: 270
//}

// 2023 Money
//export const Money = {
//    [TEAM_ID.BOBNICKS]: 75,
//    [TEAM_ID.SCHRODINGER]: 400,
//    [TEAM_ID.INITTOWINIT]: 175,
//    [TEAM_ID.BOYLE]: 0,
//    [TEAM_ID.MENDOZA]: 50,
//    [TEAM_ID.MUDCATS]: 0,
//    [TEAM_ID.HOOFHEARTED]: 50,
//    [TEAM_ID.LUMBERJACKS]: 0,
//    [TEAM_ID.TIMMAHH]: 0,
//    [TEAM_ID.PINKSOCKS]: 0
//}

export const Money = {
    [TEAM_ID.BOBNICKS]: 50,
    [TEAM_ID.SCHRODINGER]: 175,
    [TEAM_ID.INITTOWINIT]: 175,
    [TEAM_ID.MENDOZA]: 0,
    [TEAM_ID.MUDCATS]: 0,
    [TEAM_ID.HOOFHEARTED]: 250,
    [TEAM_ID.LUMBERJACKS]: 25,
    [TEAM_ID.TIMMAHH]: 0,
    [TEAM_ID.PINKSOCKS]: 0
}
