import {TEAM_ID} from "../constants/TeamId";
import tournamentOffsetSource from "./results/json/2024/championshipOffsets.json";
import weeklyOffsetSource from "./results/json/2024/championshipWeek1.json";

const getOffsets = (teamId) => (
    {
        tournament: tournamentOffsetSource.find(team => team.id === teamId).points,
        tournamentAcquisitions: tournamentOffsetSource.find(team => team.id === teamId).transactionCounter.acquisitions,
        weekly: weeklyOffsetSource.find(team => team.id === teamId).points,
    }
)

export const Offsets = {
    [TEAM_ID.BOBNICKS]: getOffsets(TEAM_ID.BOBNICKS),
    [TEAM_ID.SCHRODINGER]: getOffsets(TEAM_ID.SCHRODINGER),
    [TEAM_ID.INITTOWINIT]: getOffsets(TEAM_ID.INITTOWINIT),
    [TEAM_ID.MENDOZA]: getOffsets(TEAM_ID.MENDOZA),
    [TEAM_ID.MUDCATS]: getOffsets(TEAM_ID.MUDCATS),
    [TEAM_ID.HOOFHEARTED]: getOffsets(TEAM_ID.HOOFHEARTED),
    [TEAM_ID.LUMBERJACKS]: getOffsets(TEAM_ID.LUMBERJACKS),
    [TEAM_ID.TIMMAHH]: getOffsets(TEAM_ID.TIMMAHH),
    [TEAM_ID.PINKSOCKS]: getOffsets(TEAM_ID.PINKSOCKS),
}
