import React from 'react';
import resultsImage from './../images/2024/ChampionshipWeek2.png';
import {Link} from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: white;
`;

export const Results = () => (
    <>
    <StyledLink color="white" to="/">*** This is the previous week's results page, click here to go back to live scoring ***</StyledLink> 
    <img src={resultsImage} />
    </>
)
