import DataTable from 'react-data-table-component';
import {Offsets} from '../data/Offsets';
import {StandingsWrapper, TableStyles} from "./styles";

const columns = [
  {
    name: 'Team',
    selector: 'name',
    sortable: false,
    width: '140px'
  },
  {
    name: 'Points',
    selector: 'points',
    sortable: false,
    right: true,
    width: '75px'
  },
  {
    name: 'Day', // make green or red
    selector: 'dayChange',
    sortable: false,
    right: true,
    width: '75px'
  },
  {
    name: '# Acq', // make green or red
    selector: 'acquisitions',
    sortable: false,
    right: true,
    width: '75px'
  }
];

export const TournamentStandings = (props) => {
  const data = props.teamData.map(team => {
    const points = props.liveScoringData.length ?
      props.liveScoringData.find(liveScoring => liveScoring.teamId === team.id).pointsLive :
      team.pointsDayStart

    return ({
      id: team.id,
      name: team.name,
      points: points - Offsets[team.id]?.tournament,
      dayChange: points - team.pointsDayStart,
      acquisitions: team.acquisitions
    })
  });

  return (
    <StandingsWrapper>
    <DataTable
      title={props.name}
      noHeader={true}
      subHeader={true}
      subHeaderComponent={props.name}
      subHeaderAlign={"center"}
      columns={columns}
      data={data}
      theme={"dark"}
      defaultSortField={"points"}
      defaultSortAsc={false}
      customStyles={TableStyles}
    />
    </StandingsWrapper>
  )
}
