import React, { useEffect, useState } from 'react';
import {getData} from "./../services/espnFantasy/getData";
import {TournamentStandingsSection} from "./../components/TournamentStandingsSection";
import {SeasonStandings} from "./../components/SeasonStandings";
import {WeeklyStandings} from "./../components/WeeklyStandings";
import {MoneyStandings} from "./../components/MoneyStandings";
import {LeagueInfo} from "./../components/LeagueInfo";
import styled from "styled-components";

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const OtherStandingsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

export const Standings = () => {
    const [teamData, setTeamData] = useState([]);
  const [liveScoringData, setLiveScoringData] = useState([]);

  const setData = (items) => {
    setTeamData(items.teams.map(team => ({
      id: team.id,
      name: team.name,
      pointsDayStart: team.points,
      acquisitions: team.transactionCounter.acquisitions
    })));
    setLiveScoringData(items.schedule[0].teams.map(team => ({
      teamId: team.teamId,
      pointsLive: team.totalPointsLive
    })));
  }

  useEffect(()=>{
    getData().then(items => { setData(items) })

    const interval=setInterval(()=>{
      getData().then(items => { setData(items) })
     },10000)
       
     return()=>clearInterval(interval)
  },[])

    return (
    <>
<TournamentStandingsSection teamData={teamData} liveScoringData={liveScoringData} />
<RightSection>
  <LeagueInfo />
  <OtherStandingsSection>
    <WeeklyStandings teamData={teamData} liveScoringData={liveScoringData} />
    <SeasonStandings teamData={teamData} />
    <MoneyStandings teamData={teamData} />
  </OtherStandingsSection>
</RightSection>
</>
)

    }