import DataTable from 'react-data-table-component';
import {TeamWins} from "../data/TeamWins";
import {StandingsWrapper, TableStyles} from "./styles";

const columns = [
  {
    name: 'Team',
    selector: 'name',
    sortable: false,
    width: '140px'
  },
  {
    name: 'Wins',
    selector: 'wins',
    sortable: false,
    right: true,
    width: '75px'
  }
];

export const SeasonStandings = (props) => {
    const data = props.teamData.map(x => ({id: x.id, name: x.name, wins: TeamWins[x.id]?.wins}));

    return (
        <StandingsWrapper>
      <DataTable
        title={"Season Standings"}
        noHeader={true}
        subHeader={true}
        subHeaderComponent={"Season Standings"}
        subHeaderAlign={"center"}
        columns={columns}
        data={data}
        theme={"dark"}
        defaultSortField={"wins"}
        defaultSortAsc={false}
        customStyles={TableStyles}
      />
      </StandingsWrapper>
    )
}