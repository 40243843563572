import DataTable from 'react-data-table-component';
import {Offsets} from "../data/Offsets";
import {StandingsWrapper, TableStyles} from "./styles";
import {TeamTournaments} from "../data/TeamTournaments";
import {TOURNAMENT_ID} from "../constants/TournamentId";

const columns = [
  {
    name: 'Team',
    selector: 'name',
    sortable: false,
    width: '140px'
  },
  {
    name: 'Points',
    selector: 'points',
    sortable: false,
    right: true,
    width: '75px'
  }
];

export const WeeklyStandings = (props) => {
    const data = props.teamData.filter(team => TeamTournaments[team.id] === TOURNAMENT_ID.THE_SHOW).map(team => {
      const points = props.liveScoringData.length ?
        props.liveScoringData.find(liveScoring => liveScoring.teamId === team.id).pointsLive :
        team.pointsDayStart

      return ({
        id: team.id,
        name: team.name,
        points: points - Offsets[team.id]?.weekly});
      });

    return (
      <StandingsWrapper>
        <DataTable
          title={"Weekly Standings"}
          noHeader={true}
          subHeader={true}
          subHeaderComponent={"Weekly Standings"}
          subHeaderAlign={"center"}
          columns={columns}
          data={data}
          theme={"dark"}
          defaultSortField={"points"}
          defaultSortAsc={false}
          customStyles={TableStyles}
        />
      </StandingsWrapper>
    )
}