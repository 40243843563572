import {TEAM_ID} from "../constants/TeamId";

export const TeamWins = {
    [TEAM_ID.BOBNICKS]: {wins: 45},
    [TEAM_ID.SCHRODINGER]: {wins: 52},
    [TEAM_ID.INITTOWINIT]: {wins: 52},
    [TEAM_ID.MENDOZA]: {wins: 23},
    [TEAM_ID.MUDCATS]: {wins: 15},
    [TEAM_ID.HOOFHEARTED]: {wins: 31},
    [TEAM_ID.LUMBERJACKS]: {wins: 26},
    [TEAM_ID.TIMMAHH]: {wins: 6},
    [TEAM_ID.PINKSOCKS]: {wins: 15}
}

// 2023
//export const TeamWins = {
//    [TEAM_ID.BOBNICKS]: {wins: 43},
//    [TEAM_ID.SCHRODINGER]: {wins: 57},
//    [TEAM_ID.INITTOWINIT]: {wins: 48},
//    [TEAM_ID.BOYLE]: {wins: 0},
//    [TEAM_ID.MENDOZA]: {wins: 32},
//    [TEAM_ID.MUDCATS]: {wins: 17},
//    [TEAM_ID.HOOFHEARTED]: {wins: 38},
//    [TEAM_ID.LUMBERJACKS]: {wins: 22},
//    [TEAM_ID.TIMMAHH]: {wins: 10},
//    [TEAM_ID.PINKSOCKS]: {wins: 31}
//}

// 2022
//export const TeamWins = {
//    [TEAM_ID.BOBNICKS]: {wins: 22},
//    [TEAM_ID.SCHRODINGER]: {wins: 41},
//    [TEAM_ID.INITTOWINIT]: {wins: 34},
//    [TEAM_ID.BOYLE]: {wins: 26},
//    [TEAM_ID.MENDOZA]: {wins: 23},
//    [TEAM_ID.MUDCATS]: {wins: 34},
//    [TEAM_ID.HOOFHEARTED]: {wins: 26},
//    [TEAM_ID.LUMBERJACKS]: {wins: 20},
//    [TEAM_ID.TIMMAHH]: {wins: 37}
//}

// 2021
//export const TeamWins = {
//    [TEAM_ID.BOBNICKS]: {wins: 63},
//    [TEAM_ID.SCHRODINGER]: {wins: 34},
//    [TEAM_ID.INITTOWINIT]: {wins: 42},
//    [TEAM_ID.BOYLE]: {wins: 47},
//    [TEAM_ID.MENDOZA]: {wins: 32},
//    [TEAM_ID.MUDCATS]: {wins: 24},
//    [TEAM_ID.HOOFHEARTED]: {wins: 18},
//    [TEAM_ID.LUMBERJACKS]: {wins: 33},
//    [TEAM_ID.TIMMAHH]: {wins: 16},
//    [TEAM_ID.MOON]: {wins: 0}
//}
