import styled from "styled-components";

export const StandingsWrapper = styled.div`
  margin: 15px;
`;

export const TableStyles = {
    rows: {
      style: {
        minHeight: '30px',
        fontSize: 11
      }
    },
    subHeader: {
      style: {
        backgroundColor: '#222222',
        fontSize: 22,
        fontWeight: 500,
        paddingLeft: 'auto',
        minHeight: '30px'
      }
    },
    headRow: {
        style: {
          backgroundColor: '#222222',
          minHeight: '30px'
        }
      },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        fontSize: 14,
        fontWeight: 500
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        backgroundColor: '#333333'
      }
    }
  };