import styled from "styled-components";

const LeagueInfoContainer = styled.div`
  text-align: center;
`;

const LeagueTitle = styled.p`
  font-size: 30px;
  font-weight: bold;
`;

const TournamentTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export const LeagueInfo = () => (
    <LeagueInfoContainer>
        <LeagueTitle>Bleacher Bums 2024</LeagueTitle>
        <TournamentTitle>The Championship - Week 2</TournamentTitle>
    </LeagueInfoContainer>
)