import DataTable from 'react-data-table-component';
import {Money} from "../data/Money";
import {StandingsWrapper, TableStyles} from "./styles";

const columns = [
  {
    name: 'Team',
    selector: 'name',
    sortable: false,
    width: '140px'
  },
  {
    name: 'Money',
    selector: 'money',
    sortable: false,
    right: true,
    width: '75px'
  }
];

export const MoneyStandings = (props) => {
    const data = props.teamData.map(team => ({
        id: team.id,
        name: team.name,
        money: Money[team.id]}));

    return (
        <StandingsWrapper>
      <DataTable
        title={"Money"}
        noHeader={true}
        subHeader={true}
        subHeaderComponent={"Money"}
        subHeaderAlign={"center"}
        columns={columns}
        data={data}
        theme={"dark"}
        defaultSortField={"money"}
        defaultSortAsc={false}
        customStyles={TableStyles}
      />
      </StandingsWrapper>
    )
}