import {TournamentStandings} from "./TournamentStandings";
import {TeamTournaments} from "../data/TeamTournaments";
import {TOURNAMENT_ID} from "../constants/TournamentId";

//export const TournamentStandingsSection = (props) => (
//    <div>
//      <TournamentStandings
//        name={"The Show"}
//        teamData={props.teamData.filter(team => TeamTournaments[team.id] === TOURNAMENT_ID.THE_SHOW)}
//        liveScoringData={props.liveScoringData.filter(teamDataLive => TeamTournaments[teamDataLive.teamId] === TOURNAMENT_ID.THE_SHOW)}
//      />
//      <TournamentStandings
//        name={"A-Ball"}
//        teamData={props.teamData.filter(team => TeamTournaments[team.id] === TOURNAMENT_ID.A_BALL)}
//        liveScoringData={props.liveScoringData.filter(teamDataLive => TeamTournaments[teamDataLive.teamId] === TOURNAMENT_ID.A_BALL)}
//      />
//      <TournamentStandings
//        name={"Beer League"}
//        teamData={props.teamData.filter(team => TeamTournaments[team.id] === TOURNAMENT_ID.BEER_LEAGUE)}
//        liveScoringData={props.liveScoringData.filter(teamDataLive => TeamTournaments[teamDataLive.teamId] === TOURNAMENT_ID.BEER_LEAGUE)}
//      />
//    </div>
//)

export const TournamentStandingsSection = (props) => (
  <div>
    <TournamentStandings
      name={"The Championship"}
      teamData={props.teamData.filter(team => TeamTournaments[team.id] === TOURNAMENT_ID.THE_SHOW)}
      liveScoringData={props.liveScoringData.filter(teamDataLive => TeamTournaments[teamDataLive.teamId] === TOURNAMENT_ID.THE_SHOW)}
    />
  </div>
)