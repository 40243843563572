import {TEAM_ID} from "../constants/TeamId";
import {TOURNAMENT_ID} from "../constants/TournamentId";

export const TeamTournaments = {
    [TEAM_ID.BOBNICKS]: TOURNAMENT_ID.THE_SHOW,
    [TEAM_ID.SCHRODINGER]: TOURNAMENT_ID.THE_SHOW,
    [TEAM_ID.INITTOWINIT]: TOURNAMENT_ID.THE_SHOW,
    [TEAM_ID.MENDOZA]: TOURNAMENT_ID.BEER_LEAGUE,
    [TEAM_ID.MUDCATS]: TOURNAMENT_ID.BEER_LEAGUE,
    [TEAM_ID.HOOFHEARTED]: TOURNAMENT_ID.THE_SHOW,
    [TEAM_ID.LUMBERJACKS]: TOURNAMENT_ID.THE_SHOW,
    [TEAM_ID.TIMMAHH]: TOURNAMENT_ID.BEER_LEAGUE,
    [TEAM_ID.PINKSOCKS]: TOURNAMENT_ID.BEER_LEAGUE
}
