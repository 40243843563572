export const TEAM_ID = {
    BOBNICKS: 30,
    SCHRODINGER: 38,
    INITTOWINIT: 32,
    //BOYLE: 46,
    MENDOZA: 48,
    MUDCATS: 34,
    HOOFHEARTED: 29,
    LUMBERJACKS: 33,
    TIMMAHH: 1,
    PINKSOCKS: 49
    //MOON: 40
}
